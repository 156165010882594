import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function endsWithLanguageCode(str: string) {
  const regex = /\/(en|es|fr|it)$/
  return regex.test(str)
}

export const handleUrlClick = (url: string) => {
  if (
    !url?.startsWith('mailto:') &&
    !url?.startsWith('http://') &&
    !url?.startsWith('https://')
  ) {
    url = `https://${url}`
  }
  window.open(url, '_blank')
}

export const decodeHTMLEntities = (text: string) => {
  const parser = new DOMParser()
  const dom = parser.parseFromString(
    `<!doctype html><body>${text}`,
    'text/html'
  )
  return dom.body.textContent
}

export const industryMapping = (industry: string) => {
  switch (industry) {
    case 'Fruit & Vegetable':
      return 'AF production'
    case 'Pre - Post Harvest':
      return 'AF Pre & Post Harvesting'
    case 'Transport / Logistics':
      return 'AF Transports'
  }
  return 'AF production'
}

export const getDomain = () => {
  if (window.location.host == 'marketplace.hashagile.com') {
    return 'hashagile.com'
  } else if (window.location.hostname == 'localhost') {
    return 'localhost'
  }

  return window.location.hostname 
}

export const getCookies = () => {
  if (typeof window !== 'undefined') {
    const pairs = document?.cookie.split(';')
    const cookies: any = {}
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=')
      cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='))
    }
    return cookies
  }
  return undefined
}
